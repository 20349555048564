/* Settings */
@import '../_base/_normalize.css';
@import '../_base/_silhouettesoft.variables.css';
@import '../_base/_silhouettesoft.fonts.css';
@import '../_base/_grid.css';
@import '../_base/_base.css';
@import '../_base/_silhouettesoft.typography.css';

/* Atoms */
@import-glob '../01-atoms/**/*.css';

/* Molecules */
@import-glob '../02-molecules/**/*.css';

/* Organisms */
@import-glob '../03-organisms/**/*.css';

/* Pages */
@import-glob '../04-pages/**/*.css';

/* Themes */
@import '../_base/_silhouettesoft.themes.css';

/* Overwrites of Instalift for Perfetcha theme */
@import './themes/perfectha/perfectha-overwrites.css';

/* Header overwrite */
.header {
  @media (--viewport-md-min) {
    background: color(var(--color-white) a(75%));

    > .container {
      height: 100%;
    }

    &__inner {
      padding-top: 0;
      height: calc(100% - 28px);
      align-items: center;
    }
  }
}

/* Global navigation overwrite */
.main-navigation {
  @media (--viewport-sm-max) {
    color: var(--color-link);
  }
  @media (--viewport-md-min) {
    height: 100%;
    transform: unset;

    &-list {
      &-item {
        margin: 0;
        padding: 0 16px;
        align-items: center;
        transition: background 0.3s var(--transition);

        &:after {
          display: none;
        }

        &:hover {
          color: currentColor;
          background: var(--color-white);
        }

        &__link {
          text-shadow: unset;
          color: currentColor;
          font-weight: 400;
        }

        &-sublist {
          border-radius: 0;

          &-item {
            transition: background 0.3s var(--transition);

            &:hover {
              color: currentColor;
              background: var(--color-white);
            }
          }
        }

        &--active {
          background: var(--color-white);
        }
      }
    }
  }
}

.navigation-list {
  &-item {
    &__link {
      font-weight: 400;
    }
  }
}

.site-selector-toggle {
  &__label {
    color: var(--color-grey-dark);
    font-weight: 400;
  }
}

.navigation {
  &--service {
    &:before {
      background: color(var(--color-white) a(90%));
    }
  }
}

/* Quote overwrite */
.quote {
  &-background {
    display: none;
  }
}

/* Hero overwrites */
.hero {
  &--simple {
    padding-top: 48px;
    padding-bottom: 16px;
    color: var(--color-primary);

    @media (--viewport-md-min) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  &--video {
    .hero-head {
      flex-direction: unset;
      justify-content: unset !important;
      max-width: unset !important;

      &-title {
        color: #786569;
        font-family: effra, sans-serif;
        width: 200px;

        @media (--viewport-md-min) {
          width: 230px;
        }

        span {
          display: block;

          &:first-child {
            font-size: 64px;
            line-height: 1;

            @media (--viewport-md-min) {
              font-size: 88px;
            }
          }

          &:last-child {
            text-align: right;
          }
        }
      }
      &.hero-head--with--countdown {
        flex-direction: column;
        .countdown-content {
          justify-content: flex-start;
        }
      }
    }
  }

  &__play {
    @media (--viewport-ms-max) {
      left: 70%;
    }

    &:before {
      opacity: 0.72;
      border: 0;
      background-image: linear-gradient(216deg, #f1be9a 0%, #ffc6d4 49%, #b0afc4 100%);
    }
  }
}

/* video slider overwrites */
.video-slider {
  &-item {
    &-overlay {
      &__play {
        &:before {
          opacity: 0.72;
          border: 0;
          background-image: linear-gradient(216deg, #f1be9a 0%, #ffc6d4 49%, #b0afc4 100%);
        }
      }
    }
  }
}

/* Button overwrites */
a.button,
.button {
  border-radius: 4px;
  border: 3px solid var(--color-secondary);

  &:hover {
    background: color(var(--color-secondary) shade(10%));
    border-color: var(--color-white);
  }

  &--secondary {
    background: color(var(--color-text) a(48%));
    color: var(--color-white);
    border: 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    &:before {
      background-position: 0 0, 0 100%;
      background-repeat: no-repeat;
      -webkit-background-size: 100% 3px;
      -moz-background-size: 100% 3px;
      background-size: 100% 3px;
      background-image: linear-gradient(to right, #b0afc4 0%, #ffc6d4 49%, #f1be9a 100%),
        linear-gradient(to left, #f1be9a 0%, #ffc6d4 49%, #b0afc4 100%);
    }

    &:after {
      background-position: 0 0, 100% 0;
      background-repeat: no-repeat;
      -webkit-background-size: 3px 100%;
      -moz-background-size: 3px 100%;
      background-size: 3px 100%;
      background-image: linear-gradient(to bottom, #b0afc4 0%, #b0afc4 49%, #b0afc4 100%),
        linear-gradient(to top, #f1be9a 0%, #f1be9a 49%, #f1be9a 100%);
    }

    &:hover {
      background: var(--color-text);
    }
  }
}

/* Content cards overwrite */
.content-card {
  &-inner {
    &-content {
      &__button {
        border-radius: 100%;
      }
    }
  }
}

/* Profile cards overwrite */
.profile-card {
  &__button {
    border-radius: 100%;
  }
}

.how-it-works {
  &-content {
    &-slides {
      &-item {
        &-inner {
          @media (--viewport-md-min) {
            max-width: 600px;
            width: 100%;
          }
        }
      }
    }
  }
}

.silhouette-effect,
.silhouette-effect-2 {
  position: fixed;
  left: 50%;
  width: 4000px;
  height: 1px;
  z-index: 10;
  display: inline;
  pointer-events: none;
  -webkit-appearance: none;
  transform: rotate(30deg) translate3d(-50%, 0, 0);
  transform-origin: 0;
  mix-blend-mode: overlay;
  opacity: 0;
  transition: opacity 1s var(--transition);

  &--reveal {
    opacity: 0.9;
  }
}

.silhouette-effect {
  background-image: linear-gradient(
    to bottom,
    rgba(219, 218, 236, 0) 0%,
    rgba(219, 218, 236, 0.1) 5%,
    rgba(219, 218, 236, 0.2) 10%,
    rgba(219, 218, 236, 0.3) 15%,
    rgba(219, 218, 236, 0.4) 20%,
    rgba(219, 218, 236, 0.5) 25%,
    rgba(219, 218, 236, 0.6) 30%,
    rgba(219, 218, 236, 0.7) 35%,
    rgba(219, 218, 236, 0.8) 40%,
    rgba(219, 218, 236, 0.85) 45%,
    rgba(219, 218, 236, 0.9) 50%,
    rgba(219, 218, 236, 0.85) 55%,
    rgba(219, 218, 236, 0.8) 60%,
    rgba(219, 218, 236, 0.7) 65%,
    rgba(219, 218, 236, 0.6) 70%,
    rgba(219, 218, 236, 0.5) 75%,
    rgba(219, 218, 236, 0.4) 80%,
    rgba(219, 218, 236, 0.3) 85%,
    rgba(219, 218, 236, 0.2) 90%,
    rgba(219, 218, 236, 0.1) 95%,
    rgba(219, 218, 236, 0) 100%
  );
  top: 5%;
  height: 200px;

  @media (--viewport-sm-min) {
    top: 0%;
    height: 400px;
  }

  @media (--viewport-md-min) {
    top: -5%;
  }

  @media (--viewport-lg-min) {
    top: -9%;
  }

  @media (min-width: 1900px) {
    top: -18%;
  }
}

.silhouette-effect-2 {
  background-image: linear-gradient(
    to bottom,
    rgba(219, 218, 236, 0) 0%,
    rgba(219, 218, 236, 0.1) 5%,
    rgba(219, 218, 236, 0.2) 10%,
    rgba(219, 218, 236, 0.3) 15%,
    rgba(219, 218, 236, 0.4) 20%,
    rgba(219, 218, 236, 0.5) 25%,
    rgba(219, 218, 236, 0.6) 30%,
    rgba(219, 218, 236, 0.7) 35%,
    rgba(219, 218, 236, 0.8) 40%,
    rgba(219, 218, 236, 0.85) 45%,
    rgba(219, 218, 236, 0.9) 50%,
    rgba(219, 218, 236, 0.85) 55%,
    rgba(219, 218, 236, 0.8) 60%,
    rgba(219, 218, 236, 0.7) 65%,
    rgba(219, 218, 236, 0.6) 70%,
    rgba(219, 218, 236, 0.5) 75%,
    rgba(219, 218, 236, 0.4) 80%,
    rgba(219, 218, 236, 0.3) 85%,
    rgba(219, 218, 236, 0.2) 90%,
    rgba(219, 218, 236, 0.1) 95%,
    rgba(219, 218, 236, 0) 100%
  );
  top: 22%;
  height: 100px;

  @media (--viewport-md-min) {
    top: 16%;
    height: 300px;
  }

  @media (--viewport-lg-min) {
    top: 27%;
  }
}

.location-finder {
  &-page {
    & .header {
      @media (--viewport-md-min) {
        background: color(var(--color-white) a(75%));
      }
    }
  }
}
