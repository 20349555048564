/*
 * Base: Silhouette Soft - Themes
 * This is used to apply classes to rows in the grid, which will define a theme for all grid elements in that row.
 * An example of this is to add a blue theme, and then the quote will have a different background.
 */

.theme-silhouettesoft-violet {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-silhouettesoft-violet);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      select,
      textarea,
      .input {
        border-color: var(--color-white);
      }
    }
  }
}

.theme-silhouettesoft-grey-light {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-silhouettesoft-grey-light);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      select,
      textarea,
      .input {
        border-color: var(--color-white);
      }
    }
  }
}

.theme-silhouettesoft-blue {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-silhouettesoft-baby-blue);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      select,
      textarea,
      .input {
        border-color: var(--color-white);
      }
    }
  }
}

.theme-silhouettesoft-blue-light {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-silhouettesoft-blue-light);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      select,
      textarea,
      .input {
        border-color: var(--color-white);
      }
    }
  }
}

.theme-silhouettesoft-grey {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100.1%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-silhouettesoft-grey);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      select,
      textarea,
      .input {
        border-color: var(--color-white);
      }
    }
  }
}
